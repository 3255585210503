import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import "vuetify/dist/vuetify.min.css";
import Vuetify from "vuetify/lib";
import pt from "vuetify/es5/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#82B1FF",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },

  icons: {
    iconfont: "fa4", // 'md' || 'mdi' || 'fa' || 'fa4'
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
