<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">format_list_numbered</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            Estilos
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <router-link
            v-if="direitoCriar"
            to="/editorial/estilo"
            style="text-decoration: none"
          >
            <v-btn v-if="direitoCriar" color="success" fab small>
              <v-icon color="white" title="Incluir">add</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>
        <div class="px-4 mt-3">
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-flex xs12 md5>
              <v-text-field
                background-color="white"
                class="mt-2 mb-2 elevation-0"
                v-model="filtroPesquisa"
                append-icon="search"
                label="Digite para pesquisar..."
                placeholder="Pesquisa pelo nome"
                hide-details
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:[getItemActions()]="{ item }">
              <router-link
                :to="'/editorial/editoria/' + item.idSuperior + '/' + item.id"
                style="text-decoration: none"
              >
                <v-icon color="blue" title="Editar">edit</v-icon>
              </router-link>
              <v-icon
                v-if="direitoExcluir && !item.filhas"
                color="error"
                title="Remover"
                @click="excluir(item)"
                >delete</v-icon
              >
            </template>

        
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination
              v-model="paginaAtual"
              :length="totalPaginas"
              :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7"
              circle
            ></v-pagination>
          </div>
        </div>
        <v-snackbar
          v-model="snackbar"
          color="info"
          :timeout="5000"
          relative
          bottom
          left
          >{{ mensagem }}</v-snackbar
        >
        <v-snackbar
          v-model="snackErro"
          color="error"
          :timeout="5000"
          relative
          bottom
          left
          >{{ mensagem }}</v-snackbar
        >
      </v-card>
    </v-app>
  </div>
</template>

<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "ID Grupo", value: "idGrupo"},
        { text: "Nome", value: "nome" },
        { text: "Estilo Internet", value: "estiloInternet" },
        { text: "Font", value: "fonte" },
        { text: "Corpo", value: "corpo" },
        { text: "Kerning", value: "kerning" },
        { text: "Entrelinha", value: "entrelinha" },
        { text: "Condensacao", value: "condensacao" },
        { text: "Estilo Ação", value: "estiloAcao" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      direitoCriar: false,
      direitoEditar: false,
      direitoExcluir: false,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 50,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      snackbar: false,
      snackErro: false,
      mensagem: "",
      options: {}
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemActions() {
      return "item.actions";
    },
    getItemNumero() {
      return "item.numero";
    },
    getItemActions2() {
      return "item.actions2";
    },
    getItemTipo() {
      return "item.tipo";
    },
    getItemSituacao() {
      return "item.situacao";
    },
    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      const bodyTeste = {
        idGrupo: "72",
        idEstilo: "",
        filtroBusca: "0"
      }
      try {
        //faz a pesquisa no banco de dados

        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/buscaEstilos", bodyTeste);


        //seta as variaveis com o retorno
        this.registros = response.data.estilos;
        console.log(this.registros);

        this.registros.forEach(element => {
          element.editoria = "";
          if (element.numero.includes(".")) {
            element.editoria = "&emsp;".repeat(
              element.numero.split(".").length - 1
            );
          }
          element.editoria += element.numero + " - " + element.nome;
        });
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(
          this.totalRegistros / this.registrosPagina
        );
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa =
          this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },

    async excluir(item) {
      try {
        await api.delete(
          process.env.VUE_APP_URL_WEB_API + "edt/editoria/" + item.id
        );
        this.mensagem = "Item foi excluído";
        this.snackbar = true;
        this.pesquisaDados();
      } catch (e) {
        this.mensagem = e.response.data[0].mensagem;
        this.snackErro = true;
      }
    }
  },

  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "EDITORIAL_EDITORIAS_ACESSO"]);
    //* Verifica se o usuário logado possui direito para incluir
    this.$store.state.usuario.direitos.forEach(element => {
      var direito =
        element.modulo + "_" + element.grupo + "_" + element.direito;
      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        direito == "EDITORIAL_EDITORIAS_CRIAR"
      ) {
        this.direitoCriar = true;
      }
      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        direito == "EDITORIAL_EDITORIAS_EDITAR"
      ) {
        this.direitoEditar = true;
      }
      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        direito == "EDITORIAL_EDITORIAS_EXCLUIR"
      ) {
        this.direitoExcluir = true;
      }
    });
  },

  watch: {
    $route() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        this.pesquisaDados();
      }
    }
  }
};
</script>
