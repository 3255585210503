<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">mdi-account</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Acesso Negado!</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-layout wrap>
        <v-flex xs12>
          <v-alert :value="true" color="error" icon="block" outlined>Você não tem acesso a este recurso.</v-alert>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
export default {};
</script>

<style></style>
