<template>
  <v-app>
    <!-- Header -->
    <Header v-if="$store.state.login" />

    <!-- Menu Lateral -->
    <Sidebar v-if="$store.state.login" />

    <!-- Conteúdo principal -->
    <v-content v-if="$store.state.login || this.$route.path == '/login'">
      <router-view></router-view>
    </v-content>

    <!-- Footer -->
    <Footer v-if="$store.state.login"></Footer>
  </v-app>
</template>
<style>
@-webkit-keyframes autofill {
  to {
    color: #666;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
.v-menu__content {
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
<script>
//import Vue from "vue";
import VueCookies from "vue-cookies";
import Header from "@/components/Header.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Sidebar,
    Footer
  },
  beforeCreate() {
    if (VueCookies.isKey("token")) {
      if (!this.$store.state.usuario.id) {
        this.$store.dispatch("getUsuario");
      }
    }
  },
  data: () => ({}),
  props: {
    source: String
  }
};
</script>
