<template>
  <v-app-bar color="blue darken-3" dark app :clipped-left="$vuetify.breakpoint.mdAndUp" fixed>
    <v-toolbar-title style="width: 400px;" class="ml-0">
      <v-app-bar-nav-icon @click.stop="MOSTRAR_OCULTAR_MENU"></v-app-bar-nav-icon>
      <span class="hidden-sm-and-down font-weight-light">SuitaSIM {{ $store.state.versao }} | {{ $store.state.modulo }}</span>
    </v-toolbar-title>
    <span class="logo-jornal">
      <img v-bind:src="logotipo" :title="$store.state.usuario.site_nome + ' (' + $store.state.usuario.idSitePrincipal + '-' + $store.state.usuario.site_sigla + ')'" />
    </span>
    <v-alert v-if="!$store.state.usuario.site_situacao" :value="true" color="error" style="height:20px; margin-top: 4px; padding: 0px 4px 0px 4px;">INATIVO</v-alert>
    <v-spacer></v-spacer>

    <!-- mensagens de erro e alertas do sistema -->
    <v-btn icon @click="dialogMensagensAbrir()">
      <v-badge color="red" left v-if="mensagensCarregadas > 0" :content="mensagensCarregadas"></v-badge>
      <v-icon title="Mensagens">mail</v-icon>
    </v-btn>

    <!-- atalhos: funções mais usadas no módulo -->
    <v-menu botton offset-y transition="slide-y-reverse-transition" v-if="atalhos">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon title="Atalhos">note_add</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark small color="green">
        <v-icon title="Novo assinante">dashboard</v-icon>
      </v-btn>
      <v-btn fab dark small color="indigo">
        <v-icon title="Nova assinatura">question_answer</v-icon>
      </v-btn>
      <v-btn fab dark small color="red">
        <v-icon title="Novo...">account_circle</v-icon>
      </v-btn>
    </v-menu>

    <!-- troca de módulos -->
    <v-menu botton offset-y transition="slide-y-reverse-transition">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon title="Troca de módulos">apps</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark small color="green" class="ma-1 btn-cms" title="Módulo CMS" :href="urlCMS" v-if="moduloCMS">
        <v-badge inline color="dark" content="CMS"></v-badge>
      </v-btn>
      <router-link to="/administrativo/desktop" style="text-decoration: none;" v-if="moduloADM">
        <v-btn fab dark small color="grey" class="ma-1 btn-adm" title="Módulo Administrativo">
          <v-badge inline color="dark" content="ADM"></v-badge>
        </v-btn>
      </router-link>
      <router-link to="/assinaturas/desktop" style="text-decoration: none;" v-if="moduloASS && $store.state.usuario.site_assinaturas">
        <v-btn fab dark small color="red" class="ma-1 btn-ass" title="Módulo Assinaturas">
          <v-badge inline color="dark" content="ASS"></v-badge>
        </v-btn>
      </router-link>
      <router-link to="/comercial/desktop" style="text-decoration: none;" v-if="moduloCOM && $store.state.usuario.site_comercial">
        <v-btn fab dark small color="yellow" class="ma-1 btn-com" title="Módulo Comercial">
          <v-badge inline color="dark " content="COM"></v-badge>
        </v-btn>
      </router-link>
      <router-link to="/financeiro/desktop" style="text-decoration: none;" v-if="moduloFIN">
        <v-btn fab dark small color="black" class="ma-1 btn-fin" title="Módulo Financeiro">
          <v-badge inline color="dark" content="FIN"></v-badge>
        </v-btn>
      </router-link>

      <router-link to="/flip/desktop" style="text-decoration: none;" v-if="moduloFLIP && $store.state.usuario.site_flip">
        <v-btn fab dark small color="blue lighten-3" class="ma-1 btn-flip" title="Módulo Flip">
          <v-badge inline color="dark" content="FLIP"></v-badge>
        </v-btn>
      </router-link>

      <router-link to="/editorial/artigos" style="text-decoration: none;" v-if="moduloEDT && $store.state.usuario.site_editorial">
        <v-btn fab dark small color="blue" class="ma-1 btn-edt" title="Módulo Editorial">
          <v-badge inline color="dark" content="EDT"></v-badge>
        </v-btn>
      </router-link>
    </v-menu>

    <v-btn icon>
      <v-icon @click="deslogar" title="Sair">open_in_new</v-icon>
    </v-btn>

    <router-link :to="'/administrativo/usuario/' + $store.state.usuario.id">
      <v-icon :alt="$store.state.usuario.nome" :title="$store.state.usuario.nome">account_box</v-icon>
    </router-link>

    <v-btn icon :href="urlGuia" target="_blank">
      <v-icon title="Guia">help_outline</v-icon>
    </v-btn>

    <v-dialog v-model="dialogMensagens" max-width="1000px">
      <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
        <v-card-title class="headline grey lighten-2" primary-title>Mensagens</v-card-title>
        <v-card-text>
          <ListaMensagens :mensagensCarregadas.sync="mensagensCarregadas" />
        </v-card-text>
        <v-card-actions class="flex-wrap">
          <v-btn color="orange lighten-2 white" style="color:white" class="btn-rsn" @click="dialogMensagensFechar">
            <v-icon class="icon-rsn" left>keyboard_arrow_left</v-icon>Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import { mapMutations } from "vuex"; //importa funcão do vuex para importar as mutations do store
import ListaMensagens from "@/views/components/ListaMensagens.vue";

export default {
  components: {
    ListaMensagens
  },
  methods: {
    ...mapMutations(["MOSTRAR_OCULTAR_MENU"]), //Mostrar/esconder menu
    async deslogar() {
      try {
        await this.$store.dispatch("deslogarUsuario"); //loga o usuario
      } catch (e) {
        return;
      }
    },
    dialogMensagensFechar() {
      this.dialogMensagens = false;
    },
    dialogMensagensAbrir() {
      this.dialogMensagens = true;
      if (this.primeiraVez) {
        setTimeout(() => {
          this.primeiraVez = false;
          this.dialogMensagens = false;
        }, 1);
      }
    }
  },

  beforeMount() {
    var direito = this.$store.state.usuario.direitos[0];
    if (direito.modulo + "_" + direito.grupo + "_" + direito.direito == "WEBSUITA_ADMIN_SUITA") {
      this.moduloCMS = true;
      this.moduloADM = true;
      this.moduloASS = true;
      this.moduloCOM = true;
      this.moduloFIN = true;
      this.moduloFLIP = true;
      this.moduloEDT = true;
      this.mensagens = [];
      this.atalhos = false;
    } else {
      this.$store.state.usuario.direitos.forEach(element => {
        if (element.modulo == "CMS") {
          this.moduloCMS = true;
        }
        if (element.modulo == "ADMINISTRATIVO") {
          this.moduloADM = true;
        }
        if (element.modulo == "ASSINATURAS") {
          this.moduloASS = true;
        }
        if (element.modulo == "COMERCIAL") {
          this.moduloCOM = true;
        }
        if (element.modulo == "FINANCEIRO") {
          this.moduloFIN = true;
        }
        if (element.modulo == "EDITORIAL") {
          this.moduloEDT = true;
        }
        if (element.modulo == "FLIP") {
          this.moduloFLIP = true;
        }
      });
    }
    this.dialogMensagensAbrir();
  },
  data() {
    return {
      moduloCMS: false,
      moduloADM: false,
      moduloASS: false,
      moduloCOM: false,
      moduloFIN: false,
      moduloFLIP: false,
      moduloEDT: false,
      atalhos: false,
      dialogMensagens: false,
      mensagensCarregadas: 0,
      avatar: false,
      primeiraVez: true
    };
  },
  computed: {
    logotipo() {
      return process.env.VUE_APP_URL_WEB_API + `arquivos/${this.$store.state.usuario.idSitePrincipal}/logo.png`;
    },
    urlCMS() {
      return "https://cmsdev.cbiz.com.co/erp/login/login.aspx";
    },
    urlGuia() {
      return process.env.VUE_APP_URL_WEB_API.replace("api", "guia");
    }
  },
  watch: {
    mensagensCarregadas() {}
  }
};
</script>

<style></style>
