<template>
  <v-footer inset color="grey darken-3" class="white--text justify-center" app>
    <span>
      &nbsp;&copy; Copyright -
      <strong>
        <a target="_blank" class="estilo-link-site" href="https://suita.com.br">Suita Sistemas</a>
      </strong>
      - Todos os direitos reservados.
    </span>
  </v-footer>
</template>
<script>
export default {
  data() {
    return {
      options: { duration: 1000, offset: 0, easing: "easeInOutCubic" }
    };
  }
};
</script>
<style></style>
