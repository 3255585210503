import Vue from "vue";
import VueCookies from "vue-cookies";
import Vuex from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import menu from "./menu";

Vue.use(Vuex);
Vue.use(VueCookies);

export default new Vuex.Store({
  state: {
    versao: process.env.VUE_APP_VERSAO,
    mensagemErro: {
      tipo: "",
      mensagem: ""
    },
    login: false,
    usuario: {
      id: "",
      nome: "",
      login: "",
      idSitePrincipal: null,
      direitos: []
    },
    itens: [],
    modulo: "",
    drawer: null, //utilizado para mostrar e esconder o menu
    itensDASH: menu.itensDASH,
    itensAministrativo: menu.itensAministrativo,
    itensAministrativoPT: menu.itensAministrativoPT,
    itensAssinaturas: menu.itensAssinaturas,
    itensComercial: menu.itensComercial,
    itensFinanceiro: menu.itensFinanceiro,
    itensEditorial: menu.itensEditorial,
    itensAPP: menu.itensAPP,
    itensFlip: menu.itensFlip
  },
  getters: {
    filtroMenu: state => {
      var itens = state.itens;
      //valida se possui direito
      function possuiDireito(direitoPagina) {
        //faz a busca se possui o direito
        if (state.usuario.direitos) {
          return state.usuario.direitos.some(direitoLogado => {
            //concatena o direito do usuário
            const direito =
              direitoLogado.modulo +
              "_" +
              direitoLogado.grupo +
              "_" +
              direitoLogado.direito;

            //retorna caso encontre o direito ou tenha direito de admin
            return (
              direito === direitoPagina || direito === "WEBSUITA_ADMIN_SUITA"
            );
          });
        }
      }
      var direitoAdmin = false;
      if (state.usuario.direitos.length > 0) {
        const item = state.usuario.direitos[0];
        direitoAdmin =
          item.modulo + "_" + item.grupo + "_" + item.direito ==
          "WEBSUITA_ADMIN_SUITA";
      }
      //filtra somente os menus que possui direito
      itens = itens.filter(menu => {
        //filtra os submenus
        if (menu.children) {
          menu.children = menu.children.filter(submenu => {
            return (
              possuiDireito(submenu.direito) &&
              (!submenu.text.includes("Celesc") ||
                (submenu.text.includes("Celesc") &&
                  state.usuario.site_nome == "Adjori SC")) &&
              (submenu.text != "Passivo" ||
                (submenu.text == "Passivo" &&
                  (state.usuario.site_planoContas || direitoAdmin))) &&
              (submenu.text != "Receitas" ||
                (submenu.text == "Receitas" &&
                  state.usuario.site_planoContas) ||
                direitoAdmin)
            );
          });
        }
        //filtra os menus
        if (menu.direito) {
          return possuiDireito(menu.direito);
        }
        //se não tiver direito é um menu titulo, por isso sempre true
        else {
          if (menu.children) {
            if (menu.children.length > 0) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      });
      return itens;
    }
  },
  mutations,
  actions
});
