<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">question_answer</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Módulo Assinaturas</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-layout wrap>
        <v-flex xs12>
          <v-alert :value="true" color="blue" icon="apps" outlined>Bem vindo ao módulo Assinaturas.</v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.36 - 30/07/2024:
            <br />- Limite de taxação pelo Valor Ajustado;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.35 - 23/07/2024:
            <br />- Apresentação do valor do exemplar;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.34 - 28/06/2024:
            <br />- Recibo da Reclamação; <br />- Distribuição: Mapa de Recolhimento (VA), Mapa de Reparte (VA) Mapa de Desempenho (VA), Relatório de Movimento (VA), Relatório de
            Totalização (ASS e VA); <br />- IVC: Relatório Atacado (assinantes com mais de 10 assinaturas), Relatório Geral de Assinantes e Assinaturas (atacado ou não) e de
            Assinaturas por Município (Pagas e Cortesias);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.33 - 14/06/2024:
            <br />- Bonificação de assinatura pelo período da suspensão [se Motivo de Suspensão = Bonificar Período]; <br />- Suspensão também do acesso ao conteúdo digital [se
            Motivo de Suspensão = Bloquear Acesso Digital];<br />
            - Cálculo da latitude e longitude do endereço do assinante a partir do Google Maps (requer Api Key);<br />
            - Impressão de rótulos para distribuição de assinaturas e venda avulsa por rota;
            <br />- Apresentação do endereço ALTERADO (complemento ou modo de entrega), nas alterações de endereço no roteiro; <br />- Indicação de assinaturas EXPIRADA no roteiro;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.28 - 07/03/2024:
            <br />- Indicação da Origem da Venda na captação (automática para captação online - podendo ser associada a cada plano Paywall); <br />- Inclusão de dependentes do
            CLUBE pelo próprio assinante no Paywall;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.27 - 04/03/2024:
            <br />- Relatório de comissionamento por percentuais ou metas (valor+bonificação); <br />- Forma de Pagamento padrão da banca para Faturamento de Venda Avulsa;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.26 - 26/02/2024:
            <br />- Indicação da data da edição a que se refere a reclamação no roteiro;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.25 - 22/02/2024:
            <br />- Cálculo do saldo a receber do assinante com base no número de exemplares entregues e valor pago - se campo Cancelar Parcelas do Motivo do Cancelamento for igual
            a RECALCULA;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.24 - 19/02/2024:
            <br />- Relatório consolidado de contatos com o assinante;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.23 - 08/02/2024:
            <br />- Relatório IVC; <br />- Apresentação do número de exemplares (total, ativos e entregues) do período; <br />- Workflow de contatos (independente de assinatura)
            com o assinante
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.22 - 08/01/2024:
            <br />- Inclusão de motivos de suspensão para categorização das suspensões; <br />- Configuração: se o valor do clube deve ser calculado por assinante ou não; <br />-
            Indicação do usuário que efetuou o reparte; <br />- Inclusão da modalidade e tipos de produtos na listagem de assinaturas;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.20 - 09/10/2023:
            <br />- Novo Paywall;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.19 - 07/08/2023:
            <br />- Cadastro de tipos de logradouros;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.16 - 20/07/2023:
            <br />- Possibilidade de inclusão de contas-correntes pelo próprio assinante;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.15 - 17/07/2023:
            <br />- Possibilidade de captação online somente se houver rota para o IMPRESSO; <br />- Validação do e-mail do internauta na captação online;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.14 - 19/06/2023:
            <br />- Relatório de Movimento de CARTEIRA;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.12 - 29/05/2023:
            <br />- Customização da tela Assine (Paywall) pelo próprio usuário (requer conhecimentos de HTML); <br />- Definição da regra de ajuste padrão para captação online;
            <br />- Definição dos bancos/cedentes disponíveis para captação online por débito em conta;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.11 - 16/05/2023:
            <br />- Definição da regra de ajuste padrão para a próxima renovação; <br />- Migração de plano de assinatura (permite definir o plano destino para migração na próxima
            renovação);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.10 - 05/05/2023:
            <br />- Relatório avançado de CARTEIRA;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.9 - 13/04/2023:
            <br />- Forma de cobrança e número de parcelas padrão para a próxima renovação passa a seguir o que for definido na tela principal da assinatura/captação; <br />-
            Inclusão destes campos no filtro avançado;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.8 - 10/04/2023:
            <br />- Separação de endereço de entrega de venda avulsa e assinatura;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.3 - 01/02/2023:
            <br />- Apresentação de assinaturas excluídas (ou com período excluído);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.2 - 16/01/2023:
            <br />- Se houver agendamento de suspensão finalizando-se após o término do período, assinatura permanecerá SUSPENSA (mesmo se não foi renovada = expirada);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.1 - 02/01/2023:
            <br />- Inclusão de botão para upload da arte do cupom promocional e apresentação do seu conteúdo no cadastro da promoção;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.96 - 24/11/2022:
            <br />- Encerramento do período do contrato na data do agendamento e exclusão de renovações posteriores (se possível) ao cancelar definitivamente uma assinatura;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.91 - 26/10/2022:
            <br />- Captação, agendamento de suspensão, cancelamento e alteração de endereço da edição após o horário do fechamento da edição, serão lançados após o período da
            edição fechada;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.87 - 24/08/2022:
            <br />- Histórico de Carteira de Assinaturas;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.86 - 17/08/2022:
            <br />- Filtro com dados de TODAS as renovações das Assinaturas;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.81 - 14/07/2022:
            <br />- Apresentação do roteiro (endereço, entregador, rota) no relatório de reclamações;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.80 - 12/07/2022:
            <br />- Cadastro de Modos de Entrega;<br />- Marca VIP: renovação com mais de um endereço de entrega;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.79 - 07/07/2022:
            <br />- Filtros de assinantes com/sem assinatura e com/sem e-mail;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.78 - 13/06/2022:
            <br />- Filtro de Rota e Entregador na listagem de Assinaturas (apresenta endereço de entrega vinculado a edição);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.77 - 08/06/2022:
            <br />- Possibilidade de aplicação do Filtro de Assinaturas na renovação atual ou em qualquer renovação que atenda ao filtro;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.73:
            <br />- Apresentação do endereço REMOVIDO e INCLUÍDO, nas alterações de endereço no roteiro; <br />- Indicação de assinaturas RECUPERADA, REVERTIDA, FIM-SUSPENSÃO,
            REINICIADA no roteiro;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.72:
            <br />- Função 'Reinício' - trata renovação como contrato novo no roteiro; <br />- Filtro 'Movimento' - apresenta no roteiro (somente uma vez, na próxima edição)
            contratos novos/reiniciados, suspensos/cancelados ou com endereço alterado no dia;<br />- Exclusão de assinaturas de clientes (marcados como 'renovação manual') da
            rotina de renovação automática;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.61:
            <br />- Inclusão de período de degustação no Paywall.
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.56:
            <br />- Cadastro automático do logradouro ao incluir um novo CEP;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.50:
            <br />- Apresentação no roteiro, em azul, caso o endereço tenha sido alterado nos últimos 7 dias; <br />- Histórico de roteiros emitidos; <br />- Totalização da
            Carteira de Assinaturas;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.48:
            <br />- Permite definir o tempo em que as assinaturas ficarão em RECUPERAÇÃO - se usar 0, serão canceladas imediatamente (no dia do agendamento); <br />
            <v-badge color="red" content="IMPORTANTE"></v-badge><br />
            - Parcelas ABERTAs (ainda não faturadas) serão canceladas imediatamente ao ser feito um agendamento de cancelamento (se agendamento for revertido, serão revertidas) -
            opcionalmente pode ser configurado para serem canceladas somente as parcelas com vencimento futuro;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.47:
            <br />- Captação de assinatura por código promocional; <br />- Apresentação somente de reclamações no roteiro impresso;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.44:
            <br />- Visualização de senha do assinante e dependente; <br />- Edição de dados do dependente;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.41:
            <br />- Permissão especial para forçar a exclusão de uma renovação (ou assinatura - renovação 0) - caso a renovação possua parcela em cobrança será solicitada o
            cancelamento da cobrança; caso possua parcela com nota fiscal será solicitado o cancelamento da nota; <br />- Botão [Renovar e Unir] - realiza a renovação de uma
            assinatura unindo seu período ao da renovação anterior (lembrando que o botão [Renovar] realiza a renovação com data de início=amanhã);
          </v-alert>
          <v-alert :value="true" outlined>
            4.0.39:
            <br />- Filtro e exportação de venda avulsa;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.35:
            <br />- Relatório e exportação para CSV de dados de cobrança pelas réguas/etapas;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.31:
            <br />- Possibilidade de separação da receita de venda por produto;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.30:
            <br />- Faturamento de Venda Avulsa;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.28:
            <br />- Transferência de todas as entregas futuras para o endereço de entrega do cliente, ativo e mais atual, ao desativar um endereço de entrega;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.27:
            <br />- Fixação dos setores da reclamação - reclamações do setor [Entrega/CD] poderão ser impressas no roteiro de entrega de jornais; <br />- Inclusão de status
            intermediário [Em Execução] para melhor acompanhamento do atendimento a reclamações; <br />- Novos filtros para reclamações registradas no sistema e exportação das
            reclamações para CSV; <br />- Inclusão de cores no roteiro impresso: verde=nova assinatura, azul=assinatura cancelada ou suspensa ou recuperação, vermelho=reclamação;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.26:
            <br />- Renovação de contrato expirado será com início=amanhã, porém como o início é futuro, o sistema permitirá alteração de plano, titular, data de início, etc;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.25:
            <br />- Definição da forma de cobrança e número de parcelas padrão para a próxima renovação; <br />(caso não definido, será utilizada a forma de cobrança da última
            parcela da renovação anterior e o mesmo número de parcelas);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.23:
            <br />- Taxação da carência de dias para o cálculo do vencimento das parcelas (na captação de nas renovações);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.22:
            <br />- Inclusão do conceito de EXPIRAÇÃO de assinaturas. Assinaturas de planos com renovação manual serão expiradas automaticamente se não forem renovadas até o
            término do período; <br />- Inclusão dos telefones ativos na listagem de assinantes para impressão;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.21:
            <br />- Inclusão do campo Complemento para o "complemento padrão" do endereço (caso não deseje utilizar o "complemento adicional");
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.14:
            <br />- Exclusão de renovação inserida indevidamente;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.10:
            <br />- Totalização de valores das assinaturas; <br />- Marcação de telefone como WhatsApp (também alteração do tipo de telefone: Comercial e/ou Residencial);
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.09:
            <br />- Impressão da lista de assinantes e assinaturas; <br />- Inclusão do endereço de entrega na lista impressa e exportação dos assinantes; <br />- Recibo do
            contrato de assinatura;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.08:
            <br />- Edição de assinante e assinatura em nova aba;
          </v-alert>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations(["MOSTRAR_MENU"])
  },
  beforeMount() {
    this.MOSTRAR_MENU();

    var direito = false;
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      if (element.modulo == "ASSINATURAS" || element.modulo + "_" + element.grupo + "_" + element.direito == "WEBSUITA_ADMIN_SUITA") {
        direito = true;
      }
    });
    if (!direito) {
      this.$router.push("/acesso-negado");
    }
  }
};
</script>

<style></style>
