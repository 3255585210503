<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">question_answer</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Módulo Administrativo</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-layout wrap>
        <v-flex xs12>
          <v-alert :value="true" color="blue" icon="apps" outlined>Bem vindo ao módulo Administrativo.</v-alert>
          <v-alert :value="true" outlined>
            Versão 4.1.19 - 07/08/2023:
            <br />- Cadastro de tipos de logradouros;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.88 - 29/08/2022:
            <br />- Bloqueio de formas de pagamento na captação, por perfil de usuários;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.85 - 15/08/2022:
            <br />- Compartilhamento de regras de negócios e cadastros entre Grupos de Sites de uma mesma Administração;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.83 - 26/07/2022:
            <br />- Integração de Agentes/Agências ao cadastro de Clientes/Fornecedores;
          </v-alert>
          <v-alert :value="true" outlined>
            Versão 4.0.60:
            <br />- Novo método de autenticação.
          </v-alert>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations(["MOSTRAR_MENU"])
  },
  beforeMount() {
    this.MOSTRAR_MENU();

    var direito = false;
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      if (element.modulo == "ADMINISTRATIVO" || element.modulo + "_" + element.grupo + "_" + element.direito == "WEBSUITA_ADMIN_SUITA") {
        direito = true;
      }
    });
    if (!direito) {
      this.$router.push("/acesso-negado");
    }
  }
};
</script>
<style></style>
